h2 strong {
    font-size: 24px;
}

h2 label span {
    font-size: 24px;
}

h1 {
    font-size: 32px;
    padding: 0 0 24px 0;
    font-weight: unset;
    margin: unset;
}

.error-background {
    background-color: #EA392E;
}

.errors-modal {
    text-align: center;
}